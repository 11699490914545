import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserFeedback from "../../components/userFeedback/userFeedback";
import "./SignUp.css";
import Loader from "../../components/Loader/Loader";
import { setLoading } from "../../RtkStore/slices/loaderSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "../../components/Loader/Loader.scss";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import AuthServices from "../../services/SignUpApi";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const loading = useSelector((state) => state.loader.loading);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    accountName: "",
    accountType: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstname.trim()) {
      newErrors.firstname = "First Name is required";
    }
    if (!formData.lastname.trim()) {
      newErrors.lastname = "Last Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone Number must be exactly 10 digits";
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    }
    if (!formData.accountName) {
      newErrors.accountName = "Account Name is required";
    }
    if (!formData.accountType) {
      newErrors.account = "Account Type is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    try {
      if (validateForm()) {
        const response = await AuthServices.signup(formData);

        if (response.status === 200) {
          dispatch(setLoading(false));
          window.location = "/login";
        } else {
          dispatch(setLoading(false));
          alert("An unexpected error occurred.");
        }
      } else {
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
      alert(
        error.response
          ? error.response.data
          : "Signup failed. Please try again."
      );
      return error.response;
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <>
      {loading && <Loader />}

      <div className="signUp-wrapper">
        <div className="form-container">
          <div className="formleft-side">
            <UserFeedback
              para1={
                "“Welcome to Katalisia! We're excited to have you on board. To get started, please fill out the form below with your name, email address, and a secure password. Once you've completed the form, you'll receive a confirmation email with instructions on how to access your account.”"
              }
              para2={
                "Thank you for joining Katalisia - we can't wait to see what you'll achieve!."
              }
            />
          </div>

          <div className="formright-side">
            <h2>Create Account</h2>
            <form onSubmit={handleSubmit} noValidate>
              <div className="input-container">
                <div className="form__group field">
                  <input
                    type="text"
                    className="form__field"
                    placeholder="First Name"
                    name="firstname"
                    id="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    aria-describedby="firstname-error"
                    required
                  />
                  <label htmlFor="firstname" className="form__label">
                    First Name
                  </label>
                  {errors.firstname && (
                    <span id="firstname-error" className="error-message">
                      {errors.firstname}
                    </span>
                  )}
                </div>

                <div className="form__group field">
                  <input
                    type="text"
                    className="form__field"
                    placeholder="Last Name"
                    name="lastname"
                    id="lastname"
                    value={formData.lastname}
                    onChange={handleInputChange}
                    aria-describedby="lastname-error"
                    required
                  />
                  <label htmlFor="lastname" className="form__label">
                    Last Name
                  </label>
                  {errors.lastname && (
                    <span id="lastname-error" className="error-message">
                      {errors.lastname}
                    </span>
                  )}
                </div>
              </div>

              <div className="input-container">
                <div className="form__group field">
                  <input
                    type="email"
                    className="form__field"
                    placeholder="Email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    aria-describedby="email-error"
                    required
                  />
                  <label htmlFor="email" className="form__label">
                    Email
                  </label>
                  {errors.email && (
                    <span id="email-error" className="error-message">
                      {errors.email}
                    </span>
                  )}
                </div>

                <div className="form__group field">
                  <input
                    type="tel"
                    className="form__field"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");
                    }}
                    aria-describedby="phoneNumber-error"
                    pattern="\d*"
                    maxLength="10"
                    required
                  />
                  <label htmlFor="phoneNumber" className="form__label">
                    Phone Number
                  </label>
                  {errors.phoneNumber && (
                    <span id="phoneNumber-error" className="error-message">
                      {errors.phoneNumber}
                    </span>
                  )}
                </div>
              </div>

              <div className="input-container">
                <div className="form__group field">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form__field"
                    placeholder="Password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    aria-describedby="password-error"
                    required
                    autoComplete="off"
                  />
                  <label htmlFor="password" className="form__label">
                    Password
                  </label>
                  {formData.password && (
                    <button
                      type="button"
                      className="password-mask"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon size={20} />
                      ) : (
                        <VisibilityOutlinedIcon size={20} />
                      )}
                    </button>
                  )}
                  {errors.password && (
                    <span id="password-error" className="error-message">
                      {errors.password}
                    </span>
                  )}
                </div>

                <div className="form__group field">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form__field"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    aria-describedby="confirmPassword-error"
                    required
                  />
                  <label htmlFor="confirmPassword" className="form__label">
                    Confirm Password
                  </label>
                  {formData.confirmPassword && (
                    <button
                      type="button"
                      className="password-mask"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffOutlinedIcon size={20} />
                      ) : (
                        <VisibilityOutlinedIcon size={20} />
                      )}
                    </button>
                  )}
                  {errors.confirmPassword && (
                    <span id="confirmPassword-error" className="error-message">
                      {errors.confirmPassword}
                    </span>
                  )}
                </div>
              </div>

              <div className="input-container">
                <div className="form__group field">
                  <input
                    type="text"
                    className="form__field"
                    placeholder="AccountName"
                    name="accountName"
                    id="accountName"
                    value={formData.accountName}
                    onChange={handleInputChange}
                    aria-describedby="accountName-error"
                    required
                    autoComplete="off"
                  />
                  <label htmlFor="accountName" className="form__label">
                    Account Name
                  </label>
                  {errors.accountName && (
                    <span id="accountName-error" className="error-message">
                      {errors.accountName}
                    </span>
                  )}
                </div>

                <div className="form__group field">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="accountType"
                    onChange={handleInputChange}
                  >
                    <option selected>Select Account type</option>
                    <option value="INFLUENCER">INFLUENCER</option>
                    <option value="PARTNER">PARTNER</option>
                    <option value="COMPANY">BRAND</option>
                  </select>
                  {/* <label htmlFor="password" className="form__label">Password</label> */}
                  {errors.accountType && (
                    <span id="accountType-error" className="error-message">
                      {errors.accountType}
                    </span>
                  )}
                </div>
              </div>

              <button type="submit" className="submit-button">
                Sign Up
              </button>
            </form>

            <p className="sign-in-btn">
              Already have an account? <br />
              <Link to="/login">Sign In</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
